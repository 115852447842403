const errors = {
	// auth
	REFRESH_TOKEN: 'There was an error refreshing your token',
	AUTHORIZE_NO_CREDENTIALS: 'There was an error fetching user info',
	UNAUTHORIZED: 'You must be signed in to view this page',
	UNEXPECTED_ERROR: 'An unexpected error occurred',
	PAGE_NOT_FOUND: 'The page you are looking for has not been found',
	ASSESSMENT_NOT_FOUND: 'There was an error fetching the assessment',
	ASSESSMENT_COMPLETED: 'This assessment was already completed',
	ASSESSMENT_REQUIRED: 'Please select at least one assessment to continue',
	ASSESSMENT_RESPONSE_REQUIRED: 'You must provide a response to the question',
	SCORE_OUT_OF_BOUNDS: (score: number) =>
		`Score ${score} out of bounds [0.0, 5.0]`,
	FAULTY_SIGNIN: 'An error occurred while signing you in',
};

const successes = {
	LOGGED_IN: 'Successfully signed in',
};

const info = {
	AUTHORIZING:
		'If you are not redirected momentarily, click the button below to navigate home',
};

export { errors, successes, info };
